<template>
  <tp-modal name="cancel-offbiz-modal" max-width="480px">
    <v-card>
      <v-card-title class="">
        Hủy phiếu
        {{ type }}
        #{{ code }}
      </v-card-title>

      <v-card-text class="text-body-1">
        <div>
          Hành động này sẽ không thể hoàn tác. Nhập lý do hủy vào ô bên dưới nếu
          bạn chắc chắn muốn tiếp tục:
        </div>
        <v-textarea
          v-model="cancelReason"
          class="text-body-1 mt-2"
          auto-grow
          hide-details
          placeholder="Nhập lý do hủy tại đây"
          outlined
          rows="3"
          single-line
        ></v-textarea>
      </v-card-text>

      <v-card-actions class="px-5 pb-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="closeModal()"> Bỏ qua </v-btn>
        <v-btn text color="red accent-2" @click="cancelOffBiz">
          Hủy phiếu
        </v-btn>
      </v-card-actions>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  props: {
    storeName: {
      require: true,
      type: String
    },
    homeRoute: {
      require: true,
      type: String
    },
    type: String,
    code: String,
    id: Number,
    item: {
      type: Object
    }
  },
  data() {
    return {
      cancelReason: null
    };
  },
  computed: {
    statusRequest() {
      return this.$store.getters[`${this.storeName}/statusRequest`];
    }
  },
  methods: {
    async cancelOffBiz() {
      if (
        !this.cancelReason ||
        (this.cancelReason && this.cancelReason.trim() === "")
      ) {
        this.cancelReason = null;
        return false;
      }

      await this.$store.dispatch(`${this.storeName}/cancelOffBiz`, {
        ...this.item,
        ly_do_huy: this.cancelReason
      });
      // Alert
      if (this.statusRequest.value === "success-cancelOffBiz") {
        this.closeModal();
        await this.$router.push({ name: this.homeRoute });

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: `Hủy phiếu ${this.type} thành công`
          }
        });
      } else if (this.statusRequest.value === "error-cancelOffBiz") {
        this.closeModal();
        await this.$router.push({ name: this.homeRoute });

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: `Lỗi hủy phiếu ${this.type}`
          }
        });
      }
    },

    closeModal() {
      this.$modal.hide({ name: "cancel-offbiz-modal" });
    }
  }
};
</script>

<style lang="scss" scoped></style>
