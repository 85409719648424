<template>
  <v-card flat>
    <v-card-title>Quy trình xử lý</v-card-title>

    <v-container class="px-1 pt-0 pb-5" fluid>
      <v-row class="mx-0" style="height: 100%">
        <!-- Start: Step Col -->
        <v-col class="col-wrap py-0" v-for="num in stepList.length" :key="num">
          <col-step
            :step="num"
            :step-list="stepList"
            :store-name="storeName"
          ></col-step>
        </v-col>
        <!-- End: Step col -->
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ColStep from "./components/ColStep";

export default {
  components: {
    ColStep
  },
  props: {
    stepList: {
      type: Array
    },
    storeName: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.col-wrap {
  border-right: 1px solid #e5e5e5;
  min-height: 100%;
  &:last-child {
    border-right: 0;
  }
}
</style>
